import React from 'react'
import { graphql, Link } from 'gatsby'
import { PortableText } from '@portabletext/react'
import Seo from '../components/Seo'

import * as styles from '../styles/pages/terms-and-conditions.module.scss'

const TermsAndConditionsPage = ({ data }) => {
  const {
    title,
    _rawContent
  } = data.sanityPage

  return (
    <main className='page-container-xl'>
      <Seo title={title} />
      <h1>{title}</h1>

      <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`}&nbsp;
        {title}
      </p>

      <div className={styles.pageContent}>
        <PortableText
          value={_rawContent}
        />
      </div>
    </main>
  )
}

export const query = graphql`
  query TermsAndConditionsPageQuery {
    sanityPage(slug: {current: {eq: "terms-and-conditions"}}) {
      title
      _rawContent
    }
  }
`

export default TermsAndConditionsPage